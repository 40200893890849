import React, { useState, useEffect, useContext } from 'react';
import {observer} from "mobx-react";

import * as srs from "../../../lib/srs";

import Fr_Input from "../../../lib/ui/fr_input";
import Fr_Select from "../../../lib/ui/fr_select";
import * as utils from "../../../assets/utils";

import sbo from '../../sbo.js'

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Ta_Pay from "../pay/pay.js";

class Ta_Cart extends React.Component {
  constructor(props, context) {
    super(props);

    this.pos = props.store.pos;
    this.state = {};
    this.state.mka = [];
    this.state.sval = [];
    this.state.tval = [];
    this.state.paa = [];
    this.state.uaa = [];
    this.state.laa = [];

    this.state.gnr = "001";
    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];

    this.state.wnds = {};
    this.state.msg = {};

    this.state.pro = {};
    this.state.pbo = {};
    if(props.wnds.pbo) {
        //this.state.pbo = props.wnds.pbo;
    }
      if(!sbo.cart.belopp) {
          sbo.cart.belopp = 0;
      }
      if(!sbo.cart.rows) {
          sbo.cart.rows = [];
      }
      if(!sbo.cart.pro) {
          sbo.cart.pro = {};
      }

    this.state.ynq = [
        {id: '0', text: 'NEJ'},
        {id: '1', text: 'JA'}
    ];
    this.state.debcre = [
      {id: '0', text: 'KREDIT'},
      {id: '1', text: 'DEBET'}
    ];
    this.state.tia = [
      {id: '0', text: 'Extra tomatsås', price: '1000'},
      {id: '1', text: 'Extra Majo', price: '1000'}
    ];
    this.state.tba = [
      {id: '0', text: 'Dippsås Vitlök', price: '1000'},
      {id: '1', text: 'Dippsås Majo', price: '1000'}
    ];
    this.state.soa = [
      {id: '0', text: 'Lökringar', price: '1900'},
      {id: '1', text: 'Pommes', price: '2500'}
    ];
    this.state.dra = [
      {id: '0', text: 'MER Appelsin', price: '1500'},
      {id: '1', text: 'Coca Cola', price: '2000'}
    ];
  }
  componentDidUpdate() {
  }
  componentWillUnmount() {
  }
  componentDidMount() {
    //this.btn_grps();
    //if(!this.props.wnds.pbo) this.pbo_init();
      this.cart_check();
      //alert(JSON.stringify(sbo.cart.rows));
  }

    pbo_init() {
        var pbo = {};
        pbo.text = "";
        pbo.price = "";
        //alert(JSON.stringify(rso));
        this.setState({ pbo: pbo });
        return;
    }

    dlg_style() {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        return css;
    }
    cart_row_style(crow) {
        var css = {};
        //css.width = window.innerWidth * 0.8;
        //css.height = window.innerHeight * 0.8;
        if(sbo.cfg.sid != "S0003202") {
            if(crow.gid == "009") {
                css.textDecoration = "line-through";
                //css.color = "#ff9900";
            }
        }
        return css;
    }

    wnd_close = () => {
      if(this.props.wnds.cb) {
          var rsp = {};
          rsp.ok = "999";
          this.props.wnds.cb(this.props.ctx, rsp);
      }
      else this.props.ctx.setState({ wnds: {} });
	};
    handleChange = (event, newValue) => {
        //alert(newValue);
        this.setState({ix: newValue});
      };
    toggle() {
      this.setState({modal: false});
	};

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  val_save(id, val) {
      var eko = this.state.eko;
      eko[id] = val;
        this.setState({ eko: eko });
      return;
  }
  tb_style() {
      var css = {};
      css.height = window.innerHeight - 275;
      return css;
  }
    cart_check() {
        var self = this;

        var total = 0;
        var belopp = 0;
        var suprab = 0;

        for(var row of sbo.cart.rows) {
            //belopp = ((row.nrof * 1) * (row.pris * 1));
            //belopp = (row.price * 1) * (row.antal * 1);
            belopp = (row.belopp * 1);
            if(!isNaN(belopp)) total = total + belopp;
            //total = total + belopp;
            //alert(JSON.stringify(row.pro));
            //alert(JSON.stringify(row));

            if(sbo.cfg.campaign == "SUP") {
                var plu = row.plu.substr(2, 4) * 1;
                //alert(JSON.stringify(plu));
                if((plu < 2090) || (plu > 6000)) {
                    var rabbel = Math.round((( row.pris * 1 ) * ( row.antal * 1 )) * 0.15);
                    suprab = suprab + rabbel;
                }
            }

            /*
            if(row.pro.ixo) {
                for(var crow of Object.values(row.pro.ixo)) {
                    belopp = ((crow.m0nrof * 1) * (crow.m0pris * 1));
                    if(!isNaN(belopp)) total = total + belopp;
                }
            }
            if(row.pro.txo) {
                for(var crow of Object.values(row.pro.txo)) {
                    belopp = ((crow.m0nrof * 1) * (crow.m0pris * 1));
                    if(!isNaN(belopp)) total = total + belopp;
                }
            }
            if(row.pro.sxo) {
                for(var crow of Object.values(row.pro.sxo)) {
                    belopp = ((crow.m0nrof * 1) * (crow.m0pris * 1));
                    if(!isNaN(belopp)) total = total + belopp;
                }
            }
            if(row.pro.dxo) {
                for(var crow of Object.values(row.pro.dxo)) {
                    belopp = ((crow.m0nrof * 1) * (crow.m0pris * 1));
                    if(!isNaN(belopp)) total = total + belopp;
                }
            }
            */
        }

        if(sbo.cart.pro) {
            for(var row of Object.values(sbo.cart.pro)) {
                belopp = ((row.nrof * 1) * (row.price * 1));
                if(!isNaN(belopp)) total = total + belopp;
            }
        }
        if(isNaN(total)) return;
        
        var fee = 0;
        if(sbo.cfg.fee && (sbo.cfg.fee.length > 0)) fee = (sbo.cfg.fee * 1);
        var topay = total - suprab + fee;
        var discount = suprab;

        //alert(JSON.stringify(total));
        sbo.cart.belopp = topay.toString();
        sbo.cart.levbelopp = "0";
        sbo.cart.fee = fee.toString();
        sbo.cart.discount = discount.toString();
        sbo.cart.topay = topay.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    cart_clear() {
        var self = this;

        sbo.cdo = {};
        sbo.cdo.loco = {};
        sbo.cart = {};
        sbo.cart.belopp = "0";
        sbo.cart.levbelopp = "0";
        sbo.cart.discount = "0";
        sbo.cart.topay = "0";
        sbo.cart.rows = [];
        sbo.cart.pro = {};
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    cart_reset() {
        var self = this;

        sbo.cart.belopp = "0";
        sbo.cart.levbelopp = "0";
        sbo.cart.discount = "0";
        sbo.cart.topay = "0";
        sbo.cart.rows = [];
        sbo.cart.pro = {};
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
    }
    xcart_pay() {
        var self = this;

        this.cart_sell();
    }

    cart_sell() {
    var self = this;

    var rows = sbo.cart.rows;
    var belopp = sbo.cart.belopp;
    if(!rows || (rows.length < 1)) {
        alert("INGET ATT SÄLJA");
        return;
    }

    var prm = {};
    prm.req = "vcm.ps.ps_web_sell";
    prm.avd = "01";
    prm.tablenr = "9001";
    prm.cashier = "00";
    prm.betkod = "card";
    prm.bettxt = "CARD PAYMENT";
    prm.belopp = belopp;
    prm.rows = rows;

    //net.sio_req(prm)
    srs.sio_req(prm)
    .then(function(ret){
        //alert(JSON.stringify(ret));
        if(ret.ok == "000") {
        }
    })
    .catch(function(e){
        var txt = JSON.stringify(e);
    });
  }
    imgUrl(fil) {
        var url = "";
        url = "https://story.svepos.se/foodle/";
        url = url + fil;
        return url;
    }
    row_del(row) {
        sbo.cart.belopp = 0;
        if(!sbo.cart.rows) sbo.cart.rows = [];

        //sbo.cart.rows = sbo.cart.rows.splice(key - 1, 1);
        var belopp = 0;
        var rows = [];
        for(var crow of sbo.cart.rows) {
            if(crow.plu != row.plu) {
                belopp = belopp + (crow.belopp * 1);
                rows.push(crow);
            }
        }
        sbo.cart.rows = rows;
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));

        var gte = {};
        gte.remove = {};
        gte.remove.actionField = {};
        gte.remove.actionField.list = "Varukorg";
        gte.remove.products = [];
        if(true) {
            var o = {};
            o.id = row.plu;
            o.name = row.text;
            o.price = row.belopp;
            o.brand = "";
            o.category = "";
            o.variant = "";
            o.quantity = row.antal;
            o.coupon = "";
            gte.remove.products.push(o);
        }

        //alert(JSON.stringify(gte));
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({ event : "remove", ecommerce: gte });

        this.cart_check();
    }
    pro_del(row) {
        //sbo.cart.belopp = 0;
        //if(!sbo.cart.rows) sbo.cart.rows = [];

        var belopp = sbo.cart.belopp * 1;
        if(!sbo.cart.pro[row.plu]) {
            return;
        }
        delete( sbo.cart.pro[row.plu] );
        belopp = belopp - ((row.nrof * 1) * (row.price * 1));
        //belopp = belopp - (row.price * 1);
        sbo.cart.belopp = belopp.toString();
        sessionStorage.setItem("sbo", JSON.stringify(sbo));
        this.cart_check();
    }
    pro_ina(row) {
        var a = [];
        //alert(JSON.stringify(row.pro.ino));
        if(row.pro.ino) a = Object.values(row.pro.ino);
        return a;
    }
    pro_ixa(row) {
        var a = [];
        if(row.pro.ixo) a = Object.values(row.pro.ixo);
        return a;
    }
    pro_txa(row) {
        var a = [];
        if(row.pro.txo) a = Object.values(row.pro.txo);
        return a;
    }
    pro_sxa(row) {
        var a = [];
        if(row.pro.sxo) a = Object.values(row.pro.sxo);
        return a;
    }
    pro_dxa(row) {
        var a = [];
        if(row.pro.dxo) a = Object.values(row.pro.dxo);
        return a;
    }
    cart_pay() {
        var self = this;
        if(this.props.wnds.cb) {
            var rsp = {};
            rsp.ok = "000";
            this.props.wnds.cb(this.props.ctx, rsp);
        }
        else this.props.ctx.setState({ wnds: {} });
    }
    zcart_pay() {
        var self = this;
        var wnds = {};
        wnds.cartpay = true;
        wnds.cb = this.cart_cb;
        this.setState({ wnds: wnds });
    }
    cart_cb(ctx, rsp) {
        if(rsp.ok == "000") {
            ctx.props.ctx.setState({ wnds: {} });
        }
    }
    not_min() {
      var brc = false;
      var minbelopp = 1;
      var belopp = sbo.cart.belopp * 1;
        if(belopp < minbelopp) {
            brc = true;
        }
        return brc;
    }

  render() {
    //if(!this.props.show) return "";
    var title = this.props.title;
    if(!title) title = "Resurs";

	var html =
        <div className="mx_base bg-white black">
        <div className="m2_backdrop" />
        <div className="mx_right flex-col" style={ this.dlg_style() } >
        <div className="mx_content flex">
            <div className="mx-close-btn" onClick={() => this.wnd_close() }><FontAwesomeIcon icon="xmark" size="lg" /></div>

            <div className="flex-cl py-3">
            <div className="flex-row px-5">
                <div className="txt_b18">Varukorg</div>
                <div className="flex"></div>
            </div>
            <div className="flex-row px-5">
                <div className="txt_14">Antal varor : { sbo.cart.rows.length }</div>
            </div>
            </div>
            <div className="divider_orange"></div>

            <div className="mx-body px-5">

            <div className="mpg">
                <div className="flex-row">
                    <div className="txt_b16">Produkter</div>
                </div>
                <div className="flex-col px-2">
                    { sbo.cart.rows.map((crow, ckey) =>
                        <div key={ckey} className="flex-col py-3 cart-item" style={ this.cart_row_style(crow) }>
                            <div className="flex-row" >
                                <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                <div className="flex"></div>
                                <div className="txt_16">{ utils.toPris(crow.pris) } kr</div>
                            </div>
                            { this.pro_ina(crow).map((row, key) =>
                                <div key={key} className="flex-row" >
                                    <div className="txt_16">- { utils.toLang(row.p0text) }</div>
                                    <div className="flex"></div>
                                </div>
                            )}
                            { this.pro_ixa(crow).map((row, key) =>
                                <div key={key} className="flex-row" >
                                    <div className="txt_16">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_16">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_16">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            { this.pro_txa(crow).map((row, key) =>
                                <div key={key} className="flex-row" >
                                    <div className="txt_16">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_16">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_16">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            { this.pro_sxa(crow).map((row, key) =>
                                <div key={key} className="flex-row" >
                                    <div className="txt_16">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_16">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_16">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            { this.pro_dxa(crow).map((row, key) =>
                                <div key={key} className="flex-row" >
                                    <div className="txt_16">{ utils.toLang(row.m0text) }</div>
                                    <div className="flex"></div>
                                    <div className="txt_16">{ utils.toNum(row.m0nrof) } * </div>
                                    <div className="txt_16">{ utils.toPris(row.m0pris) } kr</div>
                                </div>
                            )}
                            <div className="flex-row" >
                                <FontAwesomeIcon icon="trash-can" className="red" size="lg" onClick={() => this.row_del(crow) } />
                                <div className="flex"></div>
                                <div className="txt_16">Summa</div>
                                <div className="flex"></div>
                                <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                            </div>
                        </div>
                    )}
                </div>
                
                <div className="flex-col px-2">
                    { Object.values(sbo.cart.pro).map((crow, ckey) =>
                        <div key={ckey} className="flex-col py-3 cart-item" >
                            <div className="flex-row" >
                                <div className="mp-btn hide" onClick={() => this.tb_add(crow) }>XX</div>
                                <div className="txt_16">{ utils.toLang(crow.text) }</div>
                                <div className="flex"></div>
                                <div className="txt_14">{ utils.toNum(crow.nrof) } * </div>
                                <div className="txt_14">{ utils.toPris(crow.price) } kr</div>
                            </div>
                            <div className="flex-row" >
                                <FontAwesomeIcon icon="trash-can" className="red" size="lg" onClick={() => this.pro_del(crow) } />
                                <div className="flex"></div>
                                <div className="txt_16">Summa</div>
                                <div className="flex"></div>
                                <div className="txt_16">{ utils.toPris(crow.belopp) } kr</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            </div>
        </div>
        <div className="flex-row px-5">
            <div className="flex"></div>
            <div className="web-link" onClick={() => this.cart_reset() }>RENSA</div>
        </div>
        <div className="mx_foot flex-col px-5 py-2">
            <div className="flex-row">
                <div className="txt_b18">Rabatt</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( sbo.cart.discount ) } kr</div>
           </div>
           { sbo.cart.fee > "0" ? 
           <div className="flex-row">
                <div className="txt_b18">Serviceavgift</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( sbo.cart.fee ) } kr</div>
           </div>
           : null }
           <div className="flex-row">
                <div className="txt_b18">Summa</div>
                <div className="flex"></div>
                <div className="txt_b18">{ utils.toPris( sbo.cart.belopp ) } kr</div>
           </div>
            {this.not_min() ?
                <div className="flex-row">
                    <div className="mfull-btn" onClick={() => this.wnd_close()}>MINI BELOPP 1 SEK<br />EJ UPPNÅTT</div>
                </div>
                :
                <div className="flex-row">
                    <div className="mfull-btn" onClick={() => this.wnd_close()}>AVBRYT</div>
                    <div className="mfull-btn" onClick={() => this.cart_pay()}>BETALA</div>
                </div>
            }
        </div>
      </div>

        { this.state.wnds.cartpay ? <Ta_Pay wnds={this.state.wnds} ctx={this} store={this.props.store} backdrop={true}/> : null }
    </div>
	;
	return html;
  };
}

export default observer(Ta_Cart);